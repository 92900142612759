@media all and (min-width:1200px){
    #app{
      max-width: 1000px;
      margin: 0 auto;
    }
  }
  @media all and (min-width: 960px) and (max-width: 1199px) {
    #app{
      max-width: 900px;
      margin: 0 auto;
    }
  }
  
  @media all and (max-width: 599px) {
    img{
      width:100%;
      height:200px;
      object-fit: cover;
    }
  }
  
  @media all and (min-width: 600px) {
    section {
      clear: both;
      display: block;
    }
    #event-details-container{
      clear: none;
    }
    img{
      width:60%;
      height:300px;
      float: left;
      border-radius: 8px;
      object-fit: cover;
      margin-right: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
   }
  }
  
  hr {
    clear: both;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  section {
    margin: 20px;
  }
  
  .material-icons {
    padding-right: 10px;
  }
  
  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }
  
  h1, h2, h3, p {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
  }
  
  h1 {
    font-size: 48px;
  }
  
  h2 {
    margin-bottom: 0px;
  }
  
  label{
    font-size: 18px;
  }
  
  p {
    font-size: 18px;
    margin-top:10px;
    margin-bottom:0px;
  }
  
  #guestbook > p {
    font-weight: lighter;
    font-size: 14px;
  }
  
  input{
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.12);
    font-size: 14px;
    padding: 4px 0;
    background: 0 0;
    text-align: left;
    color: inherit;
  }
  
  button.clicked{
    background-color: rgb(60, 67, 154);
    border-color: rgb(60, 67, 154);
    color: white;
    outline-color: rgb(60, 67, 154);
  }
  
  button, button.firebaseui-id-submit.mdl-button--raised.mdl-button--colored{
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(60, 67, 154);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: rgb(60, 67, 154);
  cursor: default;
  display: inline-flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  margin: 8px 16px;
  outline-color: rgb(60, 67, 154);
  overflow: hidden;
  padding: 0px 15px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  }
  